import { useCallback, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import dynamic from 'next/dynamic';
import { articles } from 'api';
import { StoreContext } from 'context/Store';
import { ArticleTags } from 'types';

const Loader = dynamic(() => import('components/Loader'));

const deployProgressCheckDelay = process.env.NEXT_PUBLIC_DEPLOY_PROGRESS_CHECK_DEPLAY
  ? parseInt(process.env.NEXT_PUBLIC_DEPLOY_PROGRESS_CHECK_DEPLAY)
  : 30000;

const DeployWrapper: React.FC = ({ children }) => {
  const { isDeploy } = useContext(StoreContext);

  const { refetch } = useQuery(
    ['deploy', { page_size: 1, tags: ArticleTags.MENU, has_parents: false }],
    articles.getList,
    {
      enabled: isDeploy,
      onSuccess: () => location.reload(),
    },
  );

  const checkDeploy = useCallback(() => {
    setTimeout(() => {
      refetch();
      checkDeploy();
    }, deployProgressCheckDelay);
  }, []);

  useEffect(() => {
    if (isDeploy) {
      checkDeploy();
    }
  }, [isDeploy]);

  return (
    <Loader size="middle" height="100vh" loading={isDeploy} label={<>Deploy in progress</>}>
      {children}
    </Loader>
  );
};

export default DeployWrapper;
